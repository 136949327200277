@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.bg-gradient-clip {
  /* position: absolute; */
  /* top: 35%;
  left: 20%; */
  background: linear-gradient(
    90deg,
    #ff0000,
    #ffff00,
    #ff00f3,
    #0033ff,
    #ff00c4,
    #ff0000
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.bg-gradient-clip1 {
  background: linear-gradient(
    90deg,
    #ff0000,
    #ffff00,
    #ff00f3,
    #0033ff,
    #ff00c4,
    #ff0000
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}


.bg-gradient-clipmx {
  background: linear-gradient(
    120deg,
    #006845,
    #ebfef4,
    #cf0821,
    #ffdee2,
    #006845
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.gradient-div {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(45deg, #009fff, #9300ff);
  background-size: 200% 200%;
  animation: gradientAnimation 10s infinite;
}

@keyframes gradientAnimation {
  0%, 100% {
    background-position: 0% 100%;
  }
  30% {
    background-position: 10% 90%;
  }
  40% {
    background-position: 20% 80%;
  }
  50% {
    background-position: 30% 70%;
    background: linear-gradient(45deg, #ff00ff, #ff7700);
  }
  60% {
    background-position: 40% 60%;
  }
  70% {
    background-position: 50% 50%;
  }
  80% {
    background-position: 60% 40%;
  }
  90% {
    background-position: 70% 30%;
  }
}

.bg-gradient-clip11 {
  /* position: absolute; */
  /* top: 35%;
  left: 20%; */
  background: linear-gradient(
    90deg,
    #FF7701,
    #ff00f3,
    #0033ff,
    #ff00c4,
    #FF7701
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}


.bg-gradient-clipvn {
  background: linear-gradient(
    120deg,
    #FFCC00,
    #FFCC00,
    #00247D,
    #00247D,
    #cf0821,
    #cf0821,
    #cf0821,
    #cf0821,
    #00247D,
    #00247D,
    #FFCC00,
    #FFCC00
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}


.bg-gradient-clipsv {
  background: linear-gradient(
    120deg,
    #0045AD,
    #0045AD,
    #ebfef4,
    #0059ff,
    #ebfef4,
    #0059ff ,
    #0059ff,
    #ebfef4,
    #0059ff,
    #ebfef4,
    #0045AD,
    #0045AD
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.bg-gradient-clipcr {
  background: linear-gradient(
    120deg,
    #000C8A,
    #000C8A,
    #ebfef4,
    #DB2415,
    #ebfef4,
    #DB2415 ,
    #DB2415,
    #ebfef4,
    #DB2415,
    #ebfef4,
    #000C8A,
    #000C8A
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.bg-gradient-clipcl {
  background: linear-gradient(
    120deg,
    #FFCE00,
    #FFCE00,
    #002C88,
    #002C88,
    #C9072A,
    #C9072A,
    #C9072A,
    #C9072A,
    #002C88,
    #002C88,
    #FFCE00,
    #FFCE00
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

.Mui-selected {
  color: #4f46e5 !important; /* Cambia 'your-selected-text-color' por el color deseado */
  font-weight: bold !important;
}

.custom-scrollbar {
  scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
  -ms-overflow-style: none; /* Oculta la barra de desplazamiento en Internet Explorer */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Edge */
}



.scrollbar-hideee {
  -ms-overflow-style: none;  /* IE y Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hideee::-webkit-scrollbar {
  display: none; /* Chrome, Safari y Opera */
}


.bg-gradient-clipgt {
  background: linear-gradient(
    120deg,
    #4997d0,
    #4997d0,
    #ebfef4,
    #94c2e5,
    #ebfef4,
    #94c2e5 ,
    #94c2e5,
    #ebfef4,
    #94c2e5,
    #ebfef4,
    #4997d0,
    #4997d0
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}


.bg-gradient-cliprd {
  background: linear-gradient(
    120deg,
    #002D62,
    #002D62,
    #ebfef4,
    #CE1126,
    #ebfef4,
    #CE1126 ,
    #CE1126,
    #ebfef4,
    #CE1126,
    #ebfef4,
    #002D62,
    #002D62
  );
  background-size: 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 17s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}